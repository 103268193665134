<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.roll_stamp") }}
    </v-card-title>
    <v-card-text>
      {{ $t("messages.scan_stamp_code") }}
      <span class="error--text">
        ({{ ids.length }}/{{ needRollQuantity }})
      </span>
    </v-card-text>

    <v-card-text>
      <v-text-field
        v-model="code"
        class="c-input-xs"
        @keyup.enter="checkUidRoll"
        :label="$t('labels.stamp_code')"
        :placeholder="$t('labels.stamp_code')"
        :disabled="needRollQuantity === ids.length"
        dense
        single-line
        outlined
        clearable
        hide-details
        autofocus
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancelRoll">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirmRoll"
        :disabled="needRollQuantity !== ids.length"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "UpdateQuantityRollUid",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    quantity: {
      type: Number,
      default: () => 0,
    },
    rollIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    code: null,
    ids: [],
  }),
  computed: {
    needRollQuantity() {
      return this.item.quantity - this.quantity;
    },
  },
  mounted() {
    this.ids = [...this.rollIds];
  },
  methods: {
    async checkUidRoll() {
      if (!this.code) {
        return false;
      }

      if (this.isLoading) {
        this.code = null;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/check-roll-goods-uid",
          {
            code: this.code,
            id_goods: this.item.id_goods,
            id_goods_receipt_history: this.item.id,
          }
        );
        this.isLoading = false;
        this.code = null;
        if (this.ids.includes(data.id)) {
          this.$vToastify.error(this.$t("messages.uid_scanned"));
          document.getElementById("error_sound_player").play();
          return false;
        }
        this.ids.push(data.id);
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.code = null;
        document.getElementById("error_sound_player").play();
      }
    },
    cancelRoll() {
      this.$emit("cancel");
    },
    confirmRoll() {
      if (this.needRollQuantity !== this.ids.length) {
        return false;
      }
      this.$emit("confirm", this.ids);
      this.cancelRoll();
    },
  },
};
</script>

<style scoped></style>
